.nav-underline {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* لتحسين التمرير على الأجهزة التي تعمل باللمس */
  }
  
  .nav-item {
    white-space: nowrap;
  }
  
  .nav-link {
    display: inline-block;
  }