body {
  margin: 0;
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* index.css */

html, body, #root, .app-container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.container-xxl {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto; /* يضمن أن الفوتر يكون في أسفل الصفحة */
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none; /* يمكنك تخصيص طريقة ظهورها واختفائها باستخدام JavaScript */
}

@media (min-width: 992px) {
  .back-to-top {
    display: inline-block;
  }
}

.tajawal-regular {
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
  font-style: normal;
}




/* index.css أو Projectdetails.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 110000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 1000px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #fff;
}


.badge {
  position: absolute;
  top: -15px;
  right: -25px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
}